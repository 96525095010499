<template>
 <div class="mt-8 flow-root">
  <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
   <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
     <table class="min-w-full divide-y divide-gray-300">
      <thead class="bg-gray-50">
       <tr>
        <th
         v-for="(header, index) in thead"
         :key="index"
         scope="col"
         :class="{ 'pl-4 pr-3 sm:pl-6': index === 0, 'px-3': index !== 0 }"
         class="capitalize py-3.5 text-left"
        >
         <button
          @click="setSorting(header.sort, header.name)"
          class="text-sm font-semibold text-gray-900 group inline-flex"
         >
          {{ $t(header.name) }}

          <span
           v-if="header.isSort"
           class="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
          >
           <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-5 h-5"
           >
            <path
             fill-rule="evenodd"
             d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
             clip-rule="evenodd"
            />
           </svg>
          </span>

          <span
           v-else
           class="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
          >
           <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-5 h-5"
           >
            <path
             fill-rule="evenodd"
             d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
             clip-rule="evenodd"
            />
           </svg>
          </span>
         </button>
        </th>
        <!--   <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
          <span class="sr-only">Edit</span>
         </th>
        </tr> -->
       </tr>
      </thead>

      <tbody class="divide-y divide-gray-200 bg-white">
       <tr
        v-for="data in pagination().querySet"
        :key="data.id"
        class="hover:bg-gray-50 cursor-pointer"
        @click="$emit('actionDetail', data.id, data.mimeType, this.account)"
       >
        <td class="whitespace-nowrap py-4 pl-4 pr-3 font-medium sm:pl-6 space-x-px">
         <span class="text-sm text-gray-900">
          {{ data.fileDescription }}
         </span>
         <span class="text-xs italic text-gray-700">({{ data.fileName }})</span>
        </td>
        <td class="capitalize whitespace-nowrap px-3 py-4 text-sm text-gray-500">
         {{ $d(data.created_at, "shortText") }}
        </td>
       </tr>
      </tbody>
     </table>
     <PaginationComponent
      :backend_pagination="false"
      :current_page="1"
      :select_rows="selectRows"
      first_page_url="test"
      :from="this.rows * this.page - this.rows + 1"
      :last_page="10"
      last_page_url="data.last_page_url"
      links="data.links"
      next_page_url="data.next_page_url"
      :per_page="5"
      :to="getPageLength()"
      :total="this.tbody.length"
      @set-rows="setRows($event)"
      @action="$emit('action', $event)"
      @previousPage="previousPage()"
      @nextPage="nextPage()"
     />
    </div>
   </div>
  </div>
 </div>
</template>

<script>
import { mapGetters } from "vuex";

import PaginationComponent from "./PaginationComponent.vue";

const account =
 localStorage.getItem("account") === ""
  ? this.$store.state.user.activeAccount
  : localStorage.getItem("account");

export default {
 name: "DocumentBody",
 props: {
  data: {
   type: Array,
   required: true,
  },
  thead: {
   type: Array,
   required: true,
  },
  tbody: {
   type: Array,
   required: true,
  },
 },
 emits: ["actionDetail", "sort"],
 components: {
  PaginationComponent,
 },
 data() {
  return {
   account,
   page: 1,
   rows: 10,
   selectedRows: null,
   selectRows: [5, 10, 15, 20, 25, 30],
  };
 },
 methods: {
  setSorting(sort, header) {
   this.$emit("sort", sort);
   this.activeSorting = header;

   // Set the selected header's isSort value to the opposite of its current value
   const selectedHeader = this.thead.find((header) => header.sort === sort);
   selectedHeader.isSort = !selectedHeader.isSort;

   // Loop through the other headers and set their isSort value to false
   this.thead.forEach((header) => {
    if (header.sort !== sort) {
     header.isSort = false;
    }
   });
  },
  getPageLength() {
   if (this.page === this.pagination().pages) {
    return this.tbody.length;
   } else {
    return this.rows * this.page;
   }
  },
  setRows(event) {
   this.selectedRows = event;
  },
  pagination() {
   if (this.selectedRows) {
    this.rows = this.selectedRows;
   }
   let trimStart = (this.page - 1) * parseInt(this.rows);
   let trimEnd = trimStart + parseInt(this.rows);

   let trimmedData = this.tbody.slice(trimStart, trimEnd);

   let pages = Math.ceil(this.tbody.length / this.rows);

   return {
    querySet: trimmedData,
    pages: pages,
   };
  },
  nextPage() {
   if (this.page < this.pagination().pages) {
    this.page = parseInt(this.page) + 1;
   } else {
    this.page = this.page;
   }
  },
  previousPage() {
   if (this.page > 1) {
    this.page = parseInt(this.page) - 1;
   } else {
    this.page = this.page;
   }
  },
 },
 mounted() {},
 computed: {
  ...mapGetters(["language"]),
 },
};
</script>

<style>
.invoice_table {
 overflow-x: hidden;
 overflow-y: hidden;
}
</style>
